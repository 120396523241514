import "@fortawesome/fontawesome-free/css/all.css"

import css from "@emotion/css"
import { Container, Section, Title } from "bloomer"
import { graphql } from "gatsby"
import React from "react"

import { ModelsList } from "../components/models-list.component"
import LayoutContainer from "../layouts"

const ModelsListPage = props => {
  const models = props.data.models.edges.map(edge => edge.node)
  return (
    <LayoutContainer themeName={`dark`} title={props.data.site.siteMetadata.title} theme="dark">
      <Section
        css={css`
          margin-top: 6rem;
        `}
      >
        <Container>
          <Title>Models</Title>
          <ModelsList models={models} />
        </Container>
      </Section>
    </LayoutContainer>
  )
}

export default ModelsListPage

export const queryModelsPage = graphql`
  query modelsPageQuery {
    models: allWordpressWpModel {
      edges {
        node {
          ...ModelListFields
          acf {
            gallery_image {
              localFile {
                ...ModelGalleryImage
              }
            }
          }
        }
      }
    }
    site: site {
      siteMetadata {
        title
      }
    }
  }
`
